import { Link, type LinkProps } from '@remix-run/react'
import { cn } from '#app/utils/misc'

export function ButtonLink(props: LinkProps) {
	return (
		<Link
			{...props}
			className={cn(
				'animate-all rounded-lg border-[1px] border-[#FBAE84] bg-primary px-6 py-2 text-white shadow-md transition-transform duration-200 ease-in-out hover:-translate-y-1',
				props.className,
			)}
		>
			{props.children}
		</Link>
	)
}

export function NavItem(props: LinkProps) {
	return (
		<Link
			{...props}
			className={cn(
				'rounded-lg px-6 py-2 hover:bg-foreground/10',
				props.className,
			)}
		>
			{props.children}
		</Link>
	)
}
